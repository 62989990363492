import React from "react";
import {
    Mui
} from "@osu/react-ui";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useLocation, useHistory } from 'react-router-dom';
import clsx from 'clsx';


function getSteps() {
    return ['Select Topic', 'Check In', 'Complete'];
}

function ProgressStepper() {
    const useStyles = Mui.makeStyles((theme) => ({
        wholeWrapper: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            [theme.breakpoints.up('md')]: {
                flexDirection: 'row-reverse',
            }
        },
        stepper: {
            minWidth: "100%",
            [theme.breakpoints.up('md')]: {
                minWidth: "100%",
            },
            bottom: "25%"
        },
        backNav: {
            minWidth: "8em",
            position: "fixed",
            width: "3em",
            height: "3em",
            right: "80%",
            bottom: "5%"
        },
    }));

    const StepperConnector = Mui.withStyles({
        alternativeLabel: {
            top: 10,
            left: 'calc(-50% + 16px)',
            right: 'calc(50% + 16px)',
        },
        active: {
            '& $line': {
                borderColor: '#808080',
            },
        },
        completed: {
            '& $line': {
                borderColor: '#808080',
            },
        },
        line: {
            borderColor: '#eaeaf0',
            borderTopWidth: 3,
            borderRadius: 1,
        },
    })(Mui.StepConnector);

    const StepperIconStyles = Mui.makeStyles({
        root: {
            color: '#eaeaf0',
            display: 'flex',
            height: 22,
            alignItems: 'center',
        },
        active: {
            color: '#808080',
        },
        activeOrComplete: {
            width: 32,
            color: '#808080',
            height: 32,
            borderRadius: '100%',
            backgroundColor: 'currentColor',
        },
        circle: {
            width: 32,
            height: 32,
            border: "solid 1px #808080",
            borderRadius: '50px/50px',
        },
    });

    function StepperStepIcon(props) {
        const classes = StepperIconStyles();
        const { active, completed } = props;

        return (
            <div
                className={clsx(classes.root, {
                    [classes.active]: active,
                })}
            >
                {completed || active ? <div className={classes.activeOrComplete} /> : <div className={classes.circle} />}
            </div>
        );
    }

    const location = useLocation();
    console.log("location: ", location);
    const history = useHistory()
    console.log("history: ", history);

    let hideBackButton = location.pathname === "/" || location.pathname.indexOf("/complete") !== -1;

    let activeStep;
    switch (location.pathname) {
        case "/":
        case "/subtopic":
            activeStep = getSteps().indexOf("Select Topic");
            break;
        case "/complete":
            activeStep = getSteps().indexOf("Complete");
            break;
        default:
            activeStep = getSteps().indexOf("Check In");
    }

    const steps = getSteps();
    const classes = useStyles();

    return (
        <Mui.Box className={classes.wholeWrapper}>
            <Mui.Stepper activeStep={activeStep} alternativeLabel className={classes.stepper} connector={<StepperConnector />}>
                {steps.map((label) => (
                    <Mui.Step key={label}>
                        <Mui.StepLabel StepIconComponent={StepperStepIcon}>{label}</Mui.StepLabel>
                    </Mui.Step>
                ))}
            </Mui.Stepper>
            {!hideBackButton && (
                <Mui.Fab color="default" aria-label="Go Back" className={`margin-bottom-2 ${classes.backNav}`} variant="extended" onClick={() => history.push("/")}>
                    <ArrowBackIcon className="margin-right-2" /> BACK
                </Mui.Fab>
            )}
        </Mui.Box>
    )
}

export default ProgressStepper;

