import React from "react";
import Main from '../../Main/components'
import { MAIN_ID } from "../../util/constants";
import { withOSUTheming, useScrollToHash, Mui, withTableOfContents } from '@osu/react-ui'
import { AppProvider } from "../context";
import useCssVariableUpdater from "../hooks/useCssVariableUpdater";

function App(props = {}) {
  const { navHeight } = props
  const { ref } = useScrollToHash()
 
  const trigger = Mui.useScrollTrigger({
    disableHysteresis: true,
    threshold: navHeight
  });

  return  <div className="App" ref={ref}>
  <Main spacingTop={!trigger} className="anchor-link" id={MAIN_ID} />
</div>
}

const TOCWrappedApp = withTableOfContents(App)

function Provider(props = {}) {
  console.log('Provider props', props)
  const { authorized, handleSignIn, authStatus, handleSignOut } = props;
  const { navHeight } = useCssVariableUpdater()
  const appContext = {
    authStatus,
    authorized,
    handleSignIn,
    handleSignOut,
    navHeight
  }

  
  return (
    <AppProvider value={appContext}>
      <TOCWrappedApp  navHeight={navHeight} />
    </AppProvider>
  );
}

export default withOSUTheming(Provider)