import { combineReducers } from 'redux';
import { authentication } from './Authentication/reducers'
import { TopicsPage } from './TopicsPage/reducers';
import { CheckInByIdPage } from './CheckInByIdPage/reducers';
import { CheckInByNamePage } from './CheckInByNamePage/reducers';

export default combineReducers({
    authentication,
    TopicsPage,
    CheckInByIdPage,
    CheckInByNamePage
})