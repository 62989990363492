import { API } from 'aws-amplify';
import { generateAction } from "../redux-helpers";
import { FETCH_TOPICS, TOPICS_LOADING_STATUS, Constants } from "../actions-index";
import assert from "assert";

export function fetchTopics() {
    return async (dispatch) => {
        dispatch(generateAction(TOPICS_LOADING_STATUS, { status: Constants.TOPICS.STATUS.LOADING }));
        try {
            console.debug("Loading topics from API");
            let response = await API.get(process.env.REACT_APP_BUCKEYE_KIOSK_API_NAME, `/topics`);
            
            console.debug("Loading topics from API response: ", response);
            assertDataConformsToExpectedFormat(response);

            dispatch(generateAction(FETCH_TOPICS, { data: response, status: Constants.TOPICS.STATUS.SUCCESS }));
        } catch (error) {
            console.error("Loading topics from API error: ", error);
            error.message = error?.response?.status === 403 ? "Couldn't get topics, received permission denied" : error.message;
            dispatch(generateAction(FETCH_TOPICS, { errorMessage: error.message, status: Constants.TOPICS.STATUS.ERROR }));
        }
    }
}

function assertDataConformsToExpectedFormat(response) {
    assert(Array.isArray(response), `Couldn't get topics, expected a list but received ${typeof response} type`);
    assert(response.length > 0, "Response had 0 topics to choose from");
}
