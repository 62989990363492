export const SET_USER = 'SET_USER';
export const USER_IS_LOADING = 'USER_IS_LOADING';
export const USER_STATUS = 'USER_STATUS';
export const FETCH_USER = 'FETCH_USER';

export const SUBMIT_BUCKID = 'SUBMIT_BUCKID';
export const SUBMIT_NAME = 'SUBMIT_NAME';
export const SUBMIT_NAME_NUMBER = 'SUBMIT_NAME_NUMBER';

export const RESET_STORE = 'RESET_STORE';

export const FETCH_TOPICS = 'FETCH_TOPICS';
export const TOPICS_LOADING_STATUS = 'TOPICS_LOADING_STATUS';

export const Constants = {
    TOPICS: {
        STATUS: {
            LOADING: "loading"
            , ERROR: "error"
            , SUCCESS: "success"
        }
    }
};

/* Export each action file below */
export * from './Authentication/actions';
export * from './TopicsPage/actions';
export * from './CheckInByIdPage/actions';
export * from './CheckInByNamePage/actions';
export * from './CheckInCompletePage/actions';