import { Refresh } from "@material-ui/icons";
import { Mui, MuiLab } from "@osu/react-ui";
import React, { useEffect, useRef } from "react";
import { Fragment } from "react";

const useStyles = Mui.makeStyles((theme) => ({
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  errorAlert: {
    marginTop: "5em"
  }
}))

const id = 'error-alert'

function ErrorAlert(props = {}) {
  const { title, onClick, message, status, action = {}, ...rest } = props;
  const loading = status === 'loading'
  const classes = useStyles()
  const defaultActionText = loading ? 'Retrying' : 'Retry'
  const defaultIcon = loading ? null : <Refresh />
  const { startIcon = defaultIcon, text = defaultActionText, ...restOfAction } = action;
  const ref = useRef()
  const success = status === 'success'
  const severity = props.severity ? props.severity : success ? 'success' : 'error'
  
  useEffect(() => {    
    if (
      message 
      && ref.current instanceof Element 
      && document?.activeElement?.id !== id
    ) {
      ref.current.focus();
    }
  }, [message]);

  let Component = Mui.Box;
  let componentProps = {};
  if (message) {
    Component = MuiLab.Alert;
    componentProps = {
      ...componentProps,
      ...rest,
      severity
    }
    if (text && onClick) {
      componentProps.action = (
        <Mui.Button
          disabled={loading}
          startIcon={startIcon}
          onClick={onClick}
          color="inherit"
          size="small"
          {...restOfAction}
        >
          {text}
          {loading && <Mui.CircularProgress size={24} className={classes.buttonProgress} />}
        </Mui.Button>
      );
    }
  }

  return (
    <Component className={"margin-bottom-2" + classes.errorAlert} autoFocus id={id} tabIndex={-1} ref={ref} role="alert" aria-live="polite" {...componentProps}>
      {message && (
        <Fragment>
          {title && <MuiLab.AlertTitle>{title}</MuiLab.AlertTitle>}
          {message}
        </Fragment>
      )}
    </Component>
  );
}

ErrorAlert.defaultProps = {
    message: ''
}

export default ErrorAlert;
