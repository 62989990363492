import { SUBMIT_NAME, SUBMIT_NAME_NUMBER } from "../actions-index";

export function CheckInByNamePage(state = {}, action = {}) {
    switch (action.type) {
        case SUBMIT_NAME:
            return {
                ...state,
                name: {
                    status: 'success',
                    ...action.payload
                }
            }
        case SUBMIT_NAME_NUMBER:
            return {
                ...state,
                nameN: {
                    status: 'success',
                    ...action.payload
                }
            }
        default:
            return state
    }
}