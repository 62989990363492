import { API } from 'aws-amplify';
import { generateAction } from "../redux-helpers";
import { SUBMIT_BUCKID } from "../actions-index";

export function submitBuckID(id, topic) {
    return async (dispatch) => {
        dispatch(generateAction(SUBMIT_BUCKID, { status: "loading" }));
        try {
            console.log("submitting: ", id);
            let resp = await API.post(process.env.REACT_APP_BUCKEYE_KIOSK_API_NAME, `/submit`, {body: {
                type: "buckidSwipe",
                topic,
                id
            }})
            console.log("resp: ", resp);
            dispatch(generateAction(SUBMIT_BUCKID, { ...resp.data, status: "success" }));

        }
        catch (err) {
            console.log("err: ", err);
            dispatch(generateAction(SUBMIT_BUCKID, { status: "error" }));
        }
    }
}