import { SUBMIT_BUCKID } from "../actions-index";

export function CheckInByIdPage(state = {}, action = {}) {
    switch (action.type) {        
        case SUBMIT_BUCKID:
            return {
                ...state,
                payload: {
                    ...action.payload
                }
            }
        default:
            return state
    }
}