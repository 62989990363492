import { FETCH_TOPICS, TOPICS_LOADING_STATUS } from "../actions-index";

export function TopicsPage(state = {}, action = {}) {
    switch (action.type) {        
        case TOPICS_LOADING_STATUS:
            return {
                ...state,
                ...action.payload,
            };
        case FETCH_TOPICS:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
}