import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from "@osu/react-ui";

export function RenderableView({ pathInformation = {}, renderProps = {} }) {
    const { props = {}, Component, noIndex } = pathInformation
    const authRequired = pathInformation.authRequired
    
    return <Fragment>
        {(noIndex || authRequired) && <Helmet>
            <meta id="meta-hidden" name="robots" content="noindex" />
        </Helmet>}
        <Component 
            {...renderProps}  
            {...props} 
        />
    </Fragment>
}

RenderableView.defaultProps = {
    renderProps: {},
    pathInformation: {
        props: {},
        noIndex: false, 
        authRequired: false,
    }
}

RenderableView.propTypes = {
    pathInformation: PropTypes.shape({
        path: PropTypes.string.isRequired, 
        props: PropTypes.object, 
        title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired, 
        titleProps: PropTypes.object, 
        Component: PropTypes.oneOfType([PropTypes.element, PropTypes.object]).isRequired, 
        noIndex: PropTypes.bool, 
        authRequired: PropTypes.bool
    }).isRequired, 
    renderProps: PropTypes.object, 
    createTitle: PropTypes.func,
}

export default RenderableView