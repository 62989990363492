const FORCED_AUTHENTICATION = process.env.REACT_APP_FORCED_AUTHENTICATION === "true"
const OSU_NAV_ID = 'osu_navbar'
const MAIN_ID = 'page-content'
const ACCESSIBLE_REFLOW_HEIGHT = `399px`
const TOC_WIDTH = `15rem`

export {
    FORCED_AUTHENTICATION,
    OSU_NAV_ID,
    MAIN_ID,
    ACCESSIBLE_REFLOW_HEIGHT,
    TOC_WIDTH
}