import { lazy } from 'react';
import LogIn from '../Authentication/components/LogIn';
import LogOut from '../Authentication/components/LogOut';
import Unauthorized from './views/Unauthorized';

const TopicsPage = lazy(() => import('../TopicsPage/containers'));
const CheckInByIdPage = lazy(() => import('../CheckInByIdPage/containers'));
const CheckInByNamePage = lazy(() => import('../CheckInByNamePage/containers'));
const CheckInCompletePage = lazy(() => import ('../CheckInCompletePage/containers'));

const PATHS = [
    { title: "Homepage", path: '/', Component: TopicsPage },
    { title: "Subtopic Selection", path: '/subtopic', Component: TopicsPage },
    { title: "Check In by ID", path: '/check-in-by-id/:id?', Component: CheckInByIdPage },
    { title: "Check In by Name", path: '/check-in-by-name/:id?', Component: CheckInByNamePage },
    { title: "Complete", path: '/complete', Component: CheckInCompletePage}
];

const authenticationFlowPaths = [
    {
        path: '/login',
        Component: LogIn 
    },
    {
        path: '/logout',
        Component: LogOut 
    },
    {
        path: '/unauthorized',
        Component: Unauthorized 
    }
]

export {
    authenticationFlowPaths,
    PATHS as default
}