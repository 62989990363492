import { API } from 'aws-amplify';
import { generateAction } from "../redux-helpers";
import { SUBMIT_NAME_NUMBER, SUBMIT_NAME } from "../actions-index";

export function submitName(firstName, lastName, topic) {
    return async (dispatch) => {
        dispatch(generateAction(SUBMIT_NAME, { status: "loading" }));
        try {
            console.log("submitting: ", firstName, lastName);
            let resp = await API.post(process.env.REACT_APP_BUCKEYE_KIOSK_API_NAME, `/submit`, {body: {
                type: "form",
                firstName,
                lastName,
                topic
            }})
            console.log("resp: ", resp);
            dispatch(generateAction(SUBMIT_NAME, { ...resp.data, status: "success" }));

        }
        catch (err) {
            console.log("err: ", err);
            dispatch(generateAction(SUBMIT_NAME, { status: "error" }));
        }
    }
}

export function submitNameN(id, topic) {
    return async (dispatch) => {
        dispatch(generateAction(SUBMIT_NAME_NUMBER, { status: "loading" }));
        try {
            console.log("submitting: ", id);
            let resp = await API.post(process.env.REACT_APP_BUCKEYE_KIOSK_API_NAME, `/submit`, {body: {
                type: "nameN",
                id,
                topic
            }})
            console.log("resp: ", resp);
            dispatch(generateAction(SUBMIT_NAME_NUMBER, { ...resp.data, status: "success" }));

        }
        catch (err) {
            console.log("err: ", err);
            dispatch(generateAction(SUBMIT_NAME_NUMBER, { status: "error" }));
        }
    }
}