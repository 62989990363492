import React, { Fragment, Suspense } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import NoComponentFound from "../views/NoComponentFound";
import PATHS from "../paths";
import RenderableView from "./RenderableView";
import withErrorBoundary from "../../Common/hocs/withErrorBoundary";

function Routes() {
  let RoutesToRender = PATHS.map((pathInformation, index) => (
    <Route
      key={`route${encodeURIComponent(pathInformation.title)}#${index}`}
      exact
      path={pathInformation.path}
      render={(renderProps) => {
        return <RenderableView
            pathInformation={pathInformation}
            renderProps={renderProps}
          />
      }}
    />))

  return (
    <Fragment>
      <Suspense fallback={<div>Loading</div>}>
        <Switch>
          {/* {authenticationFlowPaths.map(({ path, Component }, index) =>  <Route key={`route${encodeURIComponent(path)}#${index}`} exact path={path} render={() => <Component />} />)} */}
          {RoutesToRender}
          {/* {authorized && RoutesToRender} */}
          <Route render={() => <NoComponentFound />} />
        </Switch>
      </Suspense>
    </Fragment>
  );
}

export default withRouter(withErrorBoundary(Routes));