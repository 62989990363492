import { generateAction } from "../redux-helpers";
import { SUBMIT_BUCKID, SUBMIT_NAME, SUBMIT_NAME_NUMBER } from "../actions-index";

export function resetSubmitStatus() {
    return async (dispatch) => {
        dispatch(generateAction(SUBMIT_BUCKID, { status: "cleared" }));
        dispatch(generateAction(SUBMIT_NAME, { status: "cleared" }));
        dispatch(generateAction(SUBMIT_NAME_NUMBER, { status: "cleared" }));
    }
}
