import React from "react";
import {
  withPageHeading,
  Mui,
} from "@osu/react-ui";
import Routes from "../../Routes/components";
import scarlet from "../../styles/Artboard-1.svg";
import ProgressStepper from "../../stepper/components"

const useStyles = Mui.makeStyles((theme) => {
  console.log("theme: ", theme)
  return {
    root: {
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        flexDirection: 'row-reverse'
      },
      backgroundImage: `url(${scarlet})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      minHeight: "calc(100vh - 42px - var(--nav-height) - var(--footer-height))"
    },
    whitebanner: {
        padding: "0 3%",
    },
    container: {
      minHeight: "100vh",
      height: "100vh",
    }
  };
});

function Main(props = {}) {
  const { className, unauthorized, loading, spacingTop, ...rest } = props;
  const classes = useStyles();

  return (
    <Mui.Box flexGrow={100} className={classes.whitebanner}>
    <Mui.Box flexGrow={100} className={`${className} ${classes.root}`.trim()} {...rest}>
      <Mui.Container className={classes.container} maxWidth="lg" >
      <Mui.Paper className={classes.container}>
        <Routes />
        <ProgressStepper />
      </Mui.Paper>
      </Mui.Container>
    </Mui.Box>
    </Mui.Box>
  );
}

Main.defaultProps = {
  className: "",
  tabIndex: "-1",
  component: "main",
};

export default withPageHeading(Main);